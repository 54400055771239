import React from "react"
import { Link } from "gatsby"
import UseHttpRequest from "../../hooks/HttpRequestEvents"
import Moment from "moment";
const EventsList = () => {
  const allData = UseHttpRequest()
  if (allData.length <= 0) {
    return (
      <div className="row justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
  console.log("allData.Events", allData.Events);
  const data = allData.Events.filter(
    (eventItem) => Date.parse(Moment(eventItem.EDate).format("YYYY-MM-DD")) >= new Date()
  )

  return (
    <div className="event-slider row">

      {data.map((item, index) => (
        <div key={index} className="col-12 col-lg-6 mb-3" >
          <div className="event-item">
            <div className="row">
              <div className="col-lg-4">
                <div className="event-time">
                  <h3>{Moment(item.EDate).format("MM-DD-YYYY")}</h3>
                  {/* <span>{Moment(item.EDate).format("MM-DD-YYYY")}</span> */}
                  <div className="icon">
                    <i className="flaticon-clock" />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="event-content">
                  <h4>{item.EName}</h4>
                  <ul className="event-meta">
                    <li>
                      <i className="flaticon-clock mr-1"></i>
                      {item.ETime}
                    </li>
                    <li>
                      <i className="flaticon-pin mr-1" />
                      {item.Location}
                    </li>
                  </ul>
                  <p>
                    {(item.Description).substring(0, 60) + "..."}
                  </p>
                </div>
              </div>
            </div>
            <Link className="stretched-link" to={"/event/" + item.Slug}></Link>
          </div>
        </div>
      ))
      }
    </div >
  )
}

export default EventsList
