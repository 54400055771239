import { Link } from "gatsby"
import React from "react"

const EventTitle = () => {
  return (
    <div className="page-title-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="page-title-content">
              <h2>Events</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Events</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventTitle
