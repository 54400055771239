import React from "react"
import EventTitle from "./title"
import EventsList from "./events";
const EventPage = () => {

  return (
    <>
      <EventTitle />
      <section className="event-section pt-70 pb-70">
        <div className="container">
          <div className="section-title">
            <span>
              <i className="flaticon-clipboard" />
              Upcoming Events
            </span>
            <h2>Be ready for Our Events</h2>
            <p>
              We seek to promote and co-ordinate Tamil culture, educational and community activities. We are open to all persons interested in promoting the Tamil language and culture.
            </p>
          </div>
        <EventsList/>
        </div>
      </section>
    </>
  )
}

export default EventPage
