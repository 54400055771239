import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventDetail from "../components/events/index";
const eventslist = () => {
  return (
    <Layout>
      <SEO
        title="Events | Kentucky Tamil Sangam"
        desc="Events | Kentucky Tamil Sangam"
        pathname="/kyts-events"
        keywords="KYTS - Events"

      />
      <EventDetail />
    </Layout>
  )
}

export default eventslist
